function os_init_sub_menus() {

  // INIT MENU TO ACTIVATE ON HOVER
  var menu_timer;
  $('.menu-activated-on-hover').on('mouseenter', 'ul.main-menu > li.has-sub-menu', function () {
    var $elem = $(this);
    clearTimeout(menu_timer);
    $elem.closest('ul').addClass('has-active').find('> li').removeClass('active');
    $elem.addClass('active');
  });

  $('.menu-activated-on-hover').on('mouseleave', 'ul.main-menu > li.has-sub-menu', function () {
    var $elem = $(this);
    menu_timer = setTimeout(function () {
      $elem.removeClass('active').closest('ul').removeClass('has-active');
    }, 30);
  });

  // INIT MENU TO ACTIVATE ON CLICK
  $('.menu-activated-on-click').on('click', 'li.has-sub-menu > a', function (event) {
    var $elem = $(this).closest('li');
    if ($elem.hasClass('active')) {
      $elem.removeClass('active');
    } else {
      $elem.closest('ul').find('li.active').removeClass('active');
      $elem.addClass('active');
    }
    return false;
  });

}

function initializeSelect2() {
  $('.select2').each(function() {
    // Destroy any existing select2
    if ($(this).data('select2')) {
      $(this).select2('destroy');
    }

    var multiple = $(this).hasClass('select2-multiple');

    // Initialize select2
    $(this).select2({
      placeholder: $(this).data('placeholder'),
      theme: 'bootstrap-5',
      multiple: multiple,
      width: '100%'
    });

    // Add 'initialized' class
    $(this).addClass('initialized');
  });
}


$(document).on('turbo:load', function () {
  // Select2
  initializeSelect2();

  $('#resend-link').on('click', function () {
    var originalText = $(this).text();
    $(this).text('Resending...');

    setTimeout(function () {
      $('#resend-link').text(originalText);
    }, 1000);
  });

  // Dropdowns
  $('.os-dropdown-trigger').on('mouseenter', function () {
    $(this).addClass('over');
    console.log('over');
  });
  $('.os-dropdown-trigger').on('mouseleave', function () {
    $(this).removeClass('over');
  });

  os_init_sub_menus();

  // toggle password visibility
  $('.toggle-password').on('click', function () {
    var $this = $(this),
      $input = $this.closest('.input-group').find('input');
    if ($input.attr('type') == 'password') {
      $input.attr('type', 'text');
      $this.find('i').removeClass('bi-eye-slash').addClass('bi-eye');
      console.log('show');
    } else {
      $input.attr('type', 'password');
      $this.find('i').removeClass('bi-eye').addClass('bi-eye-slash');
    }
  });

  // inititalise toast and show toast
  var toastElList = [].slice.call(document.querySelectorAll('.toast'));
  var toastList = toastElList.map(function (toastEl) {
    return new bootstrap.Toast(toastEl, toastOption);
  });
  // set options
  var toastOption = {
    animation: true,
    autohide: true,
    delay: 3000
  };
  // show toast
  toastList.forEach(toast => toast.show());


  // close helpbar when modal is opened
  const contactModal = document.getElementById('contactFormModal')
  if (contactModal) {
    contactModal.addEventListener('shown.bs.modal', () => {
      // use offcanvase hidden.bs.offcanvas to close offcanvas when modal is opened
      const offcanvas = document.getElementById('helpBar')
      const offcanvasInstance = bootstrap.Offcanvas.getInstance(offcanvas)
      offcanvasInstance.hide()
    });
  }

  // datepicker
  $(".datepicker").each(function() {
    let options = {
      singleDatePicker: true,
      locale: {
        firstDay: 1,
        format: "DD/MM/YYYY",
      },
    };
    if (!$(this).prop("required")) {
      options["locale"]["cancelLabel"] = "Clear";
    }
    $(this).daterangepicker(options);
  });

  $(".datepicker:not([required=true])").on("cancel.daterangepicker", function(ev, picker) {
    $(this).val("");
  });
  // End datepicker

  // Plans page
  $('input[name="prices[interval]"]').on("change", function () {
    const interval = $(this).val();
    $("#full_hr_plan").prop("disabled", interval === "month");
  });

  $('input[name="prices[base_plan]"]').on('change', function () {
    if ($(this).is(':checked')) {
      $('.add-base-plan-btn').addClass('selected');
      $('.add-base-plan-btn').find('.btn-text').text('Currently Selected');
      $("#primary_audit").prop("disabled", false);
    } else {
      $('.add-base-plan-btn').removeClass('selected');
      $('.add-base-plan-btn').find('.btn-text').text('Select Plan');
      $("#primary_audit").prop("checked", false);
      $("#primary_audit").prop("disabled", true);
      $("#primary_audit").trigger("change");
    }
  });

  $('input[name="prices[hr_plan]"]').on("change", function () {
    const radioId = $(this).prop("id");
    const label = $(`.add-plan-btn[for="${radioId}"]`);
    if ($(this).is(":checked")) {
      label.addClass("selected");
      label.find(".btn-text").text("Currently Selected");

      const notLabel = $(`.add-plan-btn:not([for="${radioId}"])`);
      notLabel.removeClass("selected");
      notLabel.find(".btn-text").text("Select Plan");
    } else {
      label.removeClass("selected");
      label.find(".btn-text").text("Select Plan");
    }
  });

  $('input.checkbox-toggle').on("change", function () {
    const radioId = $(this).prop("id");
    console.log('change', radioId);
    const label = $(`.add-plan-btn[for="${radioId}"]`);
    $('input.checkbox-toggle').not(this).prop("checked", false);
    if ($(this).is(":checked")) {
      label.addClass("selected");
      label.find(".btn-text").text("Currently Selected");

      const notLabel = $(`.add-plan-btn:not([for="${radioId}"])`);
      notLabel.removeClass("selected");
      notLabel.find(".btn-text").text("Select Plan");
    } else {
      label.removeClass("selected");
      label.find(".btn-text").text("Select Plan");
    }
  });

  $('input[name="prices[hr_plan]"]:not(:disabled)').on("click", function () {
    // allows unchecking radio button
    const radioId = $(this).prop("id");
    const selectedLabel = $(`.selected.add-plan-btn[for="${radioId}"]`);
    if ($(this).is(":checked") && selectedLabel.length > 0) {
      $(this).prop("checked", false);
      $(this).trigger("change");
    }
  });

  function calculateTotal() {
    const interval = $('input[name="prices[interval]"]:checked').val();
    var total = 0;
    var suffix = "";

    const basePlan = $('input[name="prices[base_plan]"]:checked');
    if (basePlan.length > 0) {
      total += interval === "year" ? basePlan.data("annual-unit-amount") : basePlan.data("monthly-unit-amount");
    }

    const hrPlan = $('input[name="prices[hr_plan]"]:checked:not(:disabled)');
    if (hrPlan.length > 0) {
      total += interval === "year" ? hrPlan.data("annual-unit-amount") : hrPlan.data("monthly-unit-amount");
    }

    const primaryAudit = $("#primary_audit:checked:not(:disabled)");
    if (primaryAudit.length > 0) {
      total += primaryAudit.data("unit-amount");
    }


    const malMacPartnerPlan = $('input[name="prices[mal_mac_partner_plan]"]:checked');
    if (malMacPartnerPlan.length > 0) {
      total += (true || interval === "year") ? malMacPartnerPlan.data("annual-unit-amount") : malMacPartnerPlan.data("monthly-unit-amount");
      suffix = "/year";
    }

    const macOnlyPartnerPlan = $('input[name="prices[mac_only_partner_plan]"]:checked');
    if (macOnlyPartnerPlan.length > 0) {
      // total += (false && interval === "year") ? macOnlyPartnerPlan.data("annual-unit-amount") : macOnlyPartnerPlan.data("monthly-unit-amount");
      total = 790;
      suffix = "/month";
    }


    $(".total_amount").text((total / 100).toFixed(2) + suffix);
  }

  function updateSelectedPlan() {
    const basePlan = $('input[name="prices[base_plan]"]:checked');
    const basePlanName = (basePlan.length > 0) ? basePlan.data("plan-name") : null;
    const hrPlan = $('input[name="prices[hr_plan]"]:checked:not(:disabled)');
    const hrPlanName = (hrPlan.length > 0) ? hrPlan.data("plan-name") : null;

    const malMacPartnerPlan = $('input[name="prices[mal_mac_partner_plan]"]:checked');
    const malMacPartnerPlanName = (malMacPartnerPlan.length > 0) ? malMacPartnerPlan.data("plan-name") : null;

    const macOnlyPartnerPlan = $('input[name="prices[mac_only_partner_plan]"]:checked');
    const macOnlyPartnerPlanName = (macOnlyPartnerPlan.length > 0) ? macOnlyPartnerPlan.data("plan-name") : null;

    $(".selected_plan_name").text([basePlanName, hrPlanName, malMacPartnerPlanName, macOnlyPartnerPlanName].filter(Boolean).join(" + "));
  }

  $('#plans_form input').on("change", function () {
    updateSelectedPlan();
    calculateTotal();
  });

  $("#primary_audit").on("change", function () {
    if ($(this).is(":checked:not(:disabled)")) {
      $(".selected_addons").text($(this).data("product-name"));
    }
    else {
      $(".selected_addons").text("");
    }
  });

  updateSelectedPlan();
  calculateTotal();
  // End Plans page

  // calculate height of nav.navbar and make that available to css
  const navbarHeight = $('.navigation-wrapper').outerHeight();
  const sidebarHeight = $('.hs-sidebar').outerHeight();
  $(':root').css('--main-nav-height', navbarHeight + 'px');
  $(':root').css('--sidebar-height', sidebarHeight + 'px');
  if ($('.document-control-container').length > 0) {
    const toolbarHeight = $('.document-control-container').outerHeight();
    $(':root').css('--toolbar-height', toolbarHeight + 'px');
  }
  if ($('.editor-outer-container').length > 0) {
    const editorWidth = $('.editor-outer-container').outerWidth();
    $(':root').css('--hs-editor-width', editorWidth + 'px');
  }

  // Auto submit form
  $(".autosubmit").on("blur", function() {
    Rails.fire($(this).closest("form")[0], "submit");
  });


  if ($('.editor-outer-container').length) {
    $('body').addClass('has-editor');
    $('.hs-content').removeClass('box-style');
  }
  if ($('body').hasClass('hs_document_files') && $('body').hasClass('edit')) {
    //$('.hs-content').removeClass('box-style');
  }


  $('.modal').appendTo("body");

});
$(document).on('turbo:render', initializeSelect2);
